import React, { useMemo } from 'react'
import styled, {css} from 'styled-components'

import SbEditable from 'components/SbEditableExtended';
import ContentWrapper from 'components/contentWrapper';

import calculateBottomSpacingClass from 'components/blocks/utils/calculateBottomSpacingClass';


// Component
const Component = (props) => {

  const spacingBottomClass = useMemo(() => calculateBottomSpacingClass(props.block?.bottom_spacing), [props.block?.bottom_spacing])

  if(!props.block){
    return(<div>Storyblok block missing</div>);
  }

	return(
    <SbEditable content={props.block}>
      <StyledComponent className={`blockHtml ${spacingBottomClass}`}>
          
        {props.block.content != "" &&
          <ContentWrapper>
            <div
              className="blockHtml-control"
              dangerouslySetInnerHTML={{__html: props.block.html}}
            />
          </ContentWrapper>
        }
          
      </StyledComponent>
    </SbEditable>
	)

}

export default Component


// Styles
const StyledComponent = styled.div``